const contacts = (() => {

    const stackTables = window.stackTables

    const DOM = {
        table: document.getElementById('addr')
    }

    return {

        init: () => {

            contacts.checkMapsLoaded()

        },

        /**
         * 1. ���������, ��� ��� iframe-�������� ������-���� ������������ � ������� ���� �������
         * 2. �������� ������� ������������ ������� � ������� �����������
         *
         * TODO: ��������� ������������
         */
        checkMapsLoaded: () => {

            let scriptsCount = contacts.countMapScripts()

            if ( scriptsCount > 0 ) { // [1]

                setTimeout( contacts.checkMapsLoaded, 1000 )

            } else {

                stackTables.stackTables([DOM.table]) // [2]

            }
        },

        countMapScripts: () => {
            let maps = DOM.table.querySelectorAll('script[src*="api-maps.yandex.ru"]')
            return maps.length
        }

    }

})()

window.onload = () => {

    if (document.querySelector('body.page-contacts') && window.main.checkIfMobile() ) {
        contacts.init()
    }

}