const stackTables = (function () {

    // ���������� ������ ��������
    let _tables = []
    let _tablesStoplist = []
    let _media = '(max-width: 1010px)'

    return {

        /**
         * �������� � ��������� ������ �� ��������� ���������
         */
        init: function () {
            if ( window.matchMedia( _media ).matches ) {
                stackTables.findTables( stackTables.stackTables )
            }
        },

        /**
         * �������� ������ ������ ������, ��������� �� ������� �������� � ��������� ������� ���� ��������
         *
         * @param callback
         */
        findTables: function (callback) {
            let content = document.getElementById('center')
            let elements = content.getElementsByTagName('table')
            let tables = Array.prototype.slice.call(elements)
            let contentWidth = content.offsetWidth

            _tablesStoplist.push(document.querySelector('table#addr'))

            tables.map(function (table) {
                // ���� ������� �������� �� �������� - ���������� � ������ ��� stackTables
                if (_tablesStoplist.indexOf(table) < 0 &&
                   (table.offsetWidth > contentWidth ||
                   table.classList.contains('table-stack') ) ) {
                        _tables.push(table)
                }

                // ��������� div ������� ��� ���� ������
                let wrapperDiv = document.createElement('div')
                wrapperDiv.className = 'table-responsive'
                table.parentNode.insertBefore( wrapperDiv, table )
                wrapperDiv.appendChild(table)
            })

            if ( typeof callback === 'function' ) callback( _tables )
        },

        /**
         * ��������� ��������� ���� ��� ���� �����
         */
        stackTables: function (tables) {

            if (tables.length > 0) {

                tables.map( function (table) {
                    let elements = table.getElementsByTagName('td')
                    let rowElements = table.getElementsByTagName('tr')
                    let cells = Array.prototype.slice.call(elements)
                    let rows = Array.prototype.slice.call(rowElements)

                    cells.map( function (cell) {
                        let wrapperRow = document.createElement('tr')
                        cell.parentNode.insertBefore( wrapperRow, cell )
                        wrapperRow.appendChild(cell)
                    })

                    rows.map( function (row) {
                        // row.outerHTML = row.innerHTML
                    })
                })
            }
        }

    }

})()

document.addEventListener('DOMContentLoaded', stackTables.init)