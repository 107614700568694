let main = (function ($) {

    const DOM = {
        navigation: document.querySelector('.navigation'),
        navigationToggle: document.querySelector('.navigation__button')
    }

    const mobileMedia = '(max-width: 767px)'

    return {
        
        init: () => {
            main.events()
            main.initFancyBox()
        },
        
        events: () => {
            DOM.navigationToggle.addEventListener('click', main.toggleMenu)
        },

        toggleMenu: () => {
            DOM.navigation.classList.toggle('active')
        },

        /**
         * ���������� ��������, ����� ��������� ������ (��� �� ��������� ������)
         */
        initFancyBox: () => {

            if ( !main.checkIfMobile() ) {
                $('.fancy').fancybox()
            } else {
                $('.fancy').on('click', (e) => {
                    e.preventDefault()
                    return false
                })
            }

        },

        checkIfMobile: () => {
            return window.matchMedia(mobileMedia).matches
        }

    }

})(jQuery)

document.addEventListener('DOMContentLoaded', main.init)