const reviews = ( ($) => {

    const DOM = {

        reviews: document.querySelector('.reviews-page')

    }

    const main = window.main

    return {

        init: () => {
            if ( DOM.reviews ) {
                reviews.initOwlSlider( DOM.reviews )
            }
        },

        /**
         * �������������� ������� �� �������� � �������������� ������� �� ��������� ���������
         *
         * @param container
         */
        initOwlSlider: (container) => {
            if ( main.checkIfMobile() ) {

                let images = container.querySelectorAll('img')
                container.innerHTML = ''
                container.classList.add('owl-carousel')

                Array.prototype.forEach.call(images, (element) => {
                    container.appendChild(element)
                })

                $(container).owlCarousel({
                    items: 1,
                    loop: true,
                    dots: true,
                    nav: true
                })
            }
        }

    }

})(jQuery)


document.addEventListener('DOMContentLoaded', reviews.init)